import React from 'react';
import icon1 from "../assets/images/icon1.png"
import icon2 from "../assets/images/icon2.png"
import icon3 from "../assets/images/icon3.png"
import icon4 from "../assets/images/icon4.png"
import Fade from "react-reveal/Fade";

export default function MethodologySection() {

  return (
    // Not changing below margin to match other sections because of svg pattern spacing
    <section className="mb-24">
      <Fade bottom delays={700}>
    
      <h2 className="sm:text-lg sm:leading-snug text-center font-semibold tracking-wide uppercase text-purple-500 mb-3">
          Methodology
        </h2>
        <p className="text-primary text-center text-3xl sm:text-5xl lg:text-5xl leading-none font-extrabold tracking-tight mb-8">
          The way I work.
        </p>
        </Fade>
        <div class="flex flex-col justify-center m-auto">
   <div class="flex md:flex-row flex-col bg-teal-200 justify-center md:text-left text-center">
      <div class="flex flex-col justify-center items-center relative border-t-left">
         <div class="w-56 h-12 md:flex hidden justify-center">
            <div class="h-full  border-teal-300 border-dashed"></div>
         </div>
         <div class="rounded-full w-12 h-12 text-xl text-teal-100 bg-teal-700 font-black flex justify-center items-center absolute top-0 right-0 mt-16 shadow-lg mr-2">1</div>
         <img alt="step1" class="w-56 h-56 rounded-full shadow my-5 object-scale-down" src={icon1}/> 
         <div class="w-56 h-12 md:flex hidden justify-center">
            <div class="h-full border-r-4 border-teal-300 border-dashed"></div>
         </div>
      </div>
      <div class="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded-t-right bg-teal-200">
      
         <div class="text-xs uppercase font-bold text-teal-500">Step 1 - Idea</div>
         <div class="md:text-3xl text-xl font-bold text-teal-700">Deconstructing your idea (Why, What, When).</div>
         <div class="mt-4 text-teal-800">I look at your requirements, business needs from not only a tech savvy perspective but also as a co-founder of startups. Hence, I can develop pragmatic, targeted and tailored solutions for your requirements. </div>
     
      </div>
   </div>
   <div class="flex md:flex-row flex-col bg-orange-200 justify-center md:text-left text-center">
      <div class="flex flex-col justify-center items-center relative">
         <div class="w-56 h-12 md:flex hidden justify-center">
            <div class="h-full border-r-4 border-orange-300 border-dashed"></div>
         </div>
         <div class="rounded-full w-12 h-12 text-xl text-orange-100 bg-orange-700 font-black flex justify-center items-center absolute top-0 right-0 mt-16 shadow-lg mr-2">2</div>
         <img alt="step2" class="w-56 h-56 rounded-full shadow my-5 object-scale-down" src={icon2}/> 
         <div class="w-56 h-12 md:flex hidden justify-center">
            <div class="h-full border-r-4 border-orange-300 border-dashed"></div>
         </div>
      </div>
      <div class="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded bg-orange-200">
         <div class="text-xs uppercase font-bold text-orange-500">Step 2 - Collaboration</div>
         <div class="md:text-3xl text-xl font-bold text-orange-700">Setting up proper working methods </div>
         <div class="mt-4 text-orange-800">I prefer each projects to be broken down into milestones; hence, enabling me to apply the CI/CD concept in real life. In other words, I like autonomy but I prefer having the clients providing their inputs on each major deployments.</div>
      </div>
   </div>
   <div class="flex md:flex-row flex-col bg-indigo-200 justify-center md:text-left text-center">
      <div class="flex flex-col justify-center items-center relative">
         <div class="w-56 h-12 md:flex hidden justify-center">
            <div class="h-full border-r-4 border-indigo-300 border-dashed"></div>
         </div>
         <div class="rounded-full w-12 h-12 text-xl text-indigo-100 bg-indigo-700 font-black flex justify-center items-center absolute top-0 right-0 mt-16 shadow-lg mr-2">3</div>
         <img alt="step3" class="w-56 h-56 rounded-full shadow my-5 object-scale-down" src={icon3}/> 
         <div class="w-56 h-12 md:flex hidden justify-center">
            <div class="h-full border-r-4 border-indigo-300 border-dashed"></div>
         </div>
      </div>
      <div class="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded bg-indigo-200">
         <div class="text-xs uppercase font-bold text-indigo-500">Step 3 - Development</div>
         <div class="md:text-3xl text-xl font-bold text-indigo-700">Way of coding.</div>
         <div class="mt-4 text-indigo-800">Best practices and TDD (Test Driven Development) is my way of building things. I am lucky to be earning a living by doing what I love so I put my passion for this art in all my work. </div>
      </div>
   </div>
   <div class="flex md:flex-row flex-col bg-pink-200 justify-center md:text-left text-center">
      <div class="flex flex-col justify-center items-center relative border-b-left">
         <div class="w-56 h-12 md:flex hidden justify-center">
            <div class="h-full border-r-4 border-pink-300 border-dashed"></div>
         </div>
         <div class="rounded-full w-12 h-12 text-xl text-pink-100 bg-pink-700 font-black flex justify-center items-center absolute top-0 right-0 mt-16 shadow-lg mr-2">4</div>
         <img alt="step4" class="w-56 h-56 rounded-full shadow my-5 object-scale-down" src={icon4}/> 
         <div class="w-56 h-12 md:flex hidden justify-center">
            <div class="h-full  border-pink-300 border-dashed"></div>
         </div>
      </div>
      <div class="ml-5 p-10 flex flex-col justify-center max-w-2xl rounded-b-right bg-pink-200">
         <div class="text-xs uppercase font-bold text-pink-500">Step 4 - Release</div>
         <div class="md:text-3xl text-xl font-bold text-pink-700">Final beta testing before going live.</div>
         <div class="mt-4 text-pink-800">Quality before everything. I always prefer delivering weeks before a deadline for providing to my clients a beta testing phase. A UI/UX is not complete unless there is some feedback from real users. In this phase prior to releasing the project to the public, the clients have the possibility to share to friends, family and other team members for additional UX/UI testing before finally going live.</div>
      </div>
   </div>
</div>

     
     
    </section>
  );
}

