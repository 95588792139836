import React from 'react';
import SEO from 'react-seo-component';
import Layout from '~layouts/mainLayout';
import WelcomeSection from '~components/welcome';
import ProjectsSection from '~components/projects';
import MusicSection from '~components/music';
import BlogSection from '~components/blog';
import { useSiteMetadata } from '~hooks/useSiteMetadata';
import TestimonialsSection from '~components/testimonials';
import SkillSection from '~components/skills';
import MethodologySection from "~components/methodology"
export default function Home() {
  const {
    title,
    titleTemplate,
    description,
    image,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
  } = useSiteMetadata();
  return (
    <>
      <SEO
        title={title}
        titleTemplate={titleTemplate}
        titleSeparator="|"
        description={description || 'Welcome to my’'}
        image={`${siteUrl}${image}`}
        pathname={siteUrl}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
      />
      <Layout>
        <WelcomeSection />
        <BlogSection />
        <TestimonialsSection />
        <ProjectsSection />
        <SkillSection />
        <MethodologySection/>
        <MusicSection />
      </Layout>
    </>
  );
}
