import React from 'react';
import skills from "../assets/images/skills.png"
import Roll from 'react-reveal/Roll';


export default function SkillSection() {

  return (
    // Not changing below margin to match other sections because of svg pattern spacing
    <section className="mb-24">
     
   
      <h2 className="sm:text-lg sm:leading-snug text-center font-semibold tracking-wide uppercase text-blue mb-3">
          Skills
        </h2>

        <p className="text-primary text-center text-3xl sm:text-5xl lg:text-5xl leading-none font-extrabold tracking-tight mb-8">
          Some of the main technologies I use.
        </p>
     
        <div class="flex flex-wrap text-center justify-center">
     
     
    <img src={skills} alt="Skills image " className="img__float ld:w-full md:w-4/5 mx-auto"/>
  
      </div>
<div className="shadow-skills"></div>
     
     
    </section>
  );
}
